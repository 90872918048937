.search-input {
  display: flex;
  align-items: center;
  border: 2px solid #450c0d;
  height: 30px;
  padding: 10px 20px;
  border-radius: 9px;
  width: 60vw;
  margin: 0 auto;
  margin-top: 40px;
  max-width: 560px;
  > input {
    flex: 1;
    padding: 10px 20px;
    background-color: #ececec;
    color: #e9272d;
    border: none;
    font-size: 1rem;
    font-weight: bold;
    &::placeholder {
        color: #e9272d;
        opacity: 1;
        font-size: 0.9rem;
        font-weight: bold;
      }
    &:focus {
      outline:none;
    }
  }
  .search__inputIcon {
    color: #e9272d;
    opacity: 1;
  }
}

.search__buttons {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  button {
    margin: 5px;
    padding: 7px 15px;
    text-transform: inherit;
    color: #e9272d;
    font-weight: bold;
    &:hover {
        border: 1px solid #e9272d;
      }
  }
  .search__buttonsHidden {
    display: none !important;
  }
}
