.searchPage {
  background-color: #ececec;
  .searchPageHeader {
    display: flex;
    position: sticky;
    top: 0;
    z-index: 100;
    align-items: center;
    padding-top: 30px;
    border-bottom: 1px solid rgb(100, 255, 218);
    background-color: #e9272d;
    .searchLogo {
      margin-left: 25px;
      margin-right: 25px;
      margin-bottom: 0.5rem;
    }
    .searchPageBody {
      .search-input {
        border: 2px solid #fff;
        margin-top: 0;
        .search__inputIcon {
          color: #fff;
          opacity: 1;
        }
        > input {
          background-color: #e9272d;
          color: #fff;
          &::placeholder {
            color: #fff;
          }
        }
      }
      .search__buttons {
        margin-top: 1rem;
      }
    }
  }

  .searchResult {
    display: flex;
    flex-direction: column;
    margin: 2rem 5rem 0;
    padding-bottom: 2rem;
    .resultCount {
      color: rgb(69, 12, 13);
      font-size: 1.2rem;
      margin-bottom: 1rem;
      font-weight: bold;
      text-align: center;
    }
    .result {
      margin: 0;
      padding: 1rem;
      border-radius: 1rem;
      background-color: #fff;
      border-bottom: 2px dashed #ececec;
      width: 50%;
      display: block;
      margin-right: auto;
      margin-left: auto;
      &:last-child{
        border-bottom:0;
      }
      @media (max-width: 1024px){
        width: 75%;
      }

      @media (max-width: 768px){
        width: 100%;
      }

      .cliente {
        font-size: 1.2em;
        font-weight: bold;
        color: 000;
        text-transform: capitalize;
      }
      .vendedor {
        font-size: 1.2em;
        text-transform: capitalize;
        font-weight: bold;
        color: 000;
        margin-top: 0.5rem;
      }
    }
  }
}
