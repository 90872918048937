.home {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.home-body {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  img {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
    object-position: center bottom;
    width: auto;
  }
}
